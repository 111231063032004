import React from 'react';

const Footer = () => {
  return (
    <div id='footer' style={{padding: '60px', textAlign: 'center'}}>
      ©2023 by JENN THAU DESIGN
    </div>
  )
}

export default Footer
