import React, { useState, useEffect, useRef } from 'react';
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
// import { useInView } from 'react-intersection-observer';
import Modal from 'react-bootstrap/Modal';
import LazyHero from 'react-lazy-hero';
import logo from '../images/Jenn 2020 Logo-37.png';
import background from '../images/WWIC.png';
import Thumb from '../galleryThumb/thumb';
// import topLogo from '../images/Top Logo.png';
import neddie from '../images/designgallery/Neddie.jpg';
import mbContent from '../images/designgallery/MB-Content-icon.png';
import mbMarketing from '../images/designgallery/MB Marketing.jpg';
import sakura from '../images/designgallery/Sakuracon.png';
import kasala from '../images/designgallery/KASALA.png';
import AA from '../images/designgallery/AA Posters.png';
import wine from '../images/designgallery/Wine Thumbnail.png';
import cheese from '../images/designgallery/Cheese.jpg';
import paula from '../images/designgallery/Paula.jpg';
import lightbox from '../images/lightbox';
import './home.css'
// #ee5a61 red, #fbf39b yellow, #5eb2b8 green


const Home = () => {
  const [slides, setSlides] = useState(lightbox.cheese.map(x => {
    return ({
      src: x,
      caption: '',
      width: 1920,
      height: 'auto'
    })
  }))
  const [showModal, setShowModal] = useState(false);
  const [gallery, setGallery] = useState('');
  const [pw, setPw] = useState('');
  const [pwError, setPwError] = useState(false);
  const password = 'wombats';
  // const { ref, inView, entry } = useInView({delay: 100});
  const ref = useRef(null);

  const options = {
    buttons: {
      backgroundColor: "#1C7293",
      iconColor: "#e8eddf",
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false
    },
    caption: {
      captionColor: "#e8eddf",
      captionFontSize: "20px"
    },
    settings: {
      overlayColor: "rgba(6, 90, 130, 1)",
      lightboxTransitionSpeed: 0.1,
    }
  };
  const { openLightbox } = useLightbox();

  const goLightbox = (gallery, lock) => {
    console.log(lock)
    if (lock) {
      setGallery(gallery)
    }
    else {
      setSlides(gallery.map(x => {
        return ({
          src: x,
          caption: '',
          width: 1920,
          height: 'auto'
        })
      }))
    }
  }

  const submit = (e) => {
    e.preventDefault();
    if (pw === password) {
      hide();
      setSlides(gallery.map(x => {
        return ({
          src: x,
          caption: '',
          width: 1920,
          height: 'auto'
        })
      }))
    }
    else {
      setPwError(true)
    }
  }

  const hide = () => {
    setPwError(false);
    setGallery('');
    setPw('')
    setShowModal(false);
  }
  useEffect(() => {
    openLightbox()
  }, [slides])

  useEffect(() => {
    if (gallery) {
      setShowModal(true)
    }
  }, [gallery])

  useEffect(() => {
    if (pwError) {
      setTimeout(() => setPwError(false), 600)
    }
  }, [pwError])

  const [fadeIn, setFadeIn] = useState(false)
  useEffect(() => {
    const onScroll = e => {
      const dist = ref.current?.getBoundingClientRect().top;
      if (!fadeIn && dist && (dist / window.innerHeight < 0.66)) {
        setFadeIn(true)
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [ref]);

  return (
    <>
      <div id='home-hello'>
        <div>
          <img id='hello-logo' alt='hello' src={logo} />
          <div>
            <h1 style={{ fontSize: 'xxx-large', fontWeight: 800 }}>Hello!</h1>
            <div id='hello-text' ref={ref} className={fadeIn ? 'fade-in' : 'fade-out'}>
              {'My name is Jennifer Thau (but you can call me Jenn), I am a Graphic Designer and Illustrator based in the Seattle area. Nice to meet you!'}
            </div>
          </div>
        </div>
      </div>

      <LazyHero imageSrc={background} isFixed opacity={0} minHeight={'75vh'}>
        <div style={{ color: 'white', margin: '0 20%' }}>
          <div id='wwic'>What will I create today?</div>
          <div style={{ fontSize: 'x-large', marginTop: '20px' }}>Want to learn more? Scroll down to view some of my design work</div>
          <div style={{ fontSize: 'xx-large' }}>+</div>
        </div>
      </LazyHero>

      <div id='design'>
        <div id='home-design'>Design</div>
        <div id='work-grid'>
          <Thumb src={neddie} title={'NEDDIE DESIGNS'} text={''} lock={true} onClick={(lock) => goLightbox(lightbox.neddie, lock)} />
          {/* <Thumb src={mbContent} title={'MEDBRIDGE (CONTENT)'} text={''} lock={true} onClick={(lock) => goLightbox(lightbox.mbContent, lock)} />
          <Thumb src={mbMarketing} title={'MEDBRIDGE (MARKETING)'} text={''} lock={true} onClick={(lock) => goLightbox(lightbox.mbMarketing, lock)} /> */}
          <Thumb src={sakura} title={'SAKURACON 20TH ANNIVERSARY'} text={''} lock={false} onClick={(lock) => goLightbox(lightbox.sakuracon, lock)} />

          <Thumb src={kasala} title={'KASALA ADS'} text={''} lock={false} onClick={(lock) => goLightbox(lightbox.kasala, lock)} />
          <Thumb src={AA} title={'ALCOHOLICS ANONYMOUS POSTERS'} text={''}
            onClick={(lock) => goLightbox(lightbox.AA, lock)} lock={false} />
          {/* <Thumb src={topLogo} title={'ROMELODY'} text={''} lock={false} /> */}
          <Thumb src={paula} title={`PAULA'S CHOICE ADS`} text={''} lock={false} onClick={lock => goLightbox(lightbox.paulas, lock)} />

          {/* <Thumb src={topLogo} title={`ID SYSTEMS`} text={''} lock={false} /> */}
          <Thumb src={cheese} title={`CHEESE LABELS`} text={''} lock={false}
            onClick={(lock) => goLightbox(lightbox.cheese, lock)} />
          <Thumb src={wine} title={`WINE BOTTLES`} text={''} lock={false} onClick={lock => goLightbox(lightbox.wine, lock)} />
          {/* <Thumb src={topLogo} title={`ROCK POSTER`} text={''} lock={false} /> */}
        </div>
      </div>
      <SRLWrapper options={options} elements={slides} />

      <Modal show={showModal} onHide={() => hide()} size={'lg'} centered contentClassName={pwError ? 'auth-modal-wrap shake' : 'auth-modal-wrap'}>
        <Modal.Body>
          <div id='auth-modal'>
            <span style={{ marginRight: '10px' }}>Please enter your password to view this gallery:</span>
            <form id='auth-form' onSubmit={(e) => submit(e)}>
              <input type='password' placeholder='password' value={pw} autofocus onChange={(e) => {
                setPw(e.target.value)
              }} />
              <input type="submit" value="Submit" id='auth-submit-btn'></input>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Home;