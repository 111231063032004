import React from 'react';
import './thumb.css'

const Thumb = ({src, title, text, onClick, lock}) => {
  return (
    <div className='thumb' onClick={() => onClick(lock)}>
      <img src={src} alt={title} className='thumb-image' style={{width:'100%'}} />
      <div className='thumb-title'>{title}</div>
      <div className='thumb-middle'>
        <div className='thumb-text'>{text}</div>
      </div>
    </div>
  )
}

export default Thumb;