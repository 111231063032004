import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Navbar from './navbar/navbar';
import Home from './home/home';
import SimpleReactLightbox from "simple-react-lightbox";
import Footer from './footer/footer';
// import Resume from './resume/resume'
import './App.css';

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <Navbar />
          <Switch>
            <SimpleReactLightbox>
              <Route exact path='/' component={Home} />
            </SimpleReactLightbox>
          </Switch>
        </Router>
        <Footer />
      </div>
    </>
  );
}

export default App;
