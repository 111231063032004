import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../images/Top Logo.png';
import Modal from 'react-bootstrap/Modal';
import resume from '../images/RESUME.png';
import resumePDF from '../images/Jenn-Thau-resume.pdf'
import './navbar.css'

const Navbar = ({ history }) => {
  const [show, setShow] = useState(false);
  const hide = () => setShow(false)
  return (
    <>
      <div id='top-wrap'>
        <img id='top-logo' style={{ height: '200px', marginBottom: '60px' }} src={logo} alt='logo' />
        <nav className='navigation'>
          {/* <Link to='/'>
            HOME
          </Link> */}
          {/* <Link to='/'> */}
          <ScrollLink to='home-hello' spy={true} smooth={true} offset={-25}>
            HOME
          </ScrollLink>
          <ScrollLink to='design' spy={true} smooth={true} offset={-25}>
            DESIGN
          </ScrollLink>
          {/* </Link> */}
          {/* <Link to='/'>
          ILLUSTRATIONS
        </Link> */}
          {/* <Link to='/'>
            ABOUT ME
        </Link> */}
          <a href='mailto:jenn.thau.design@gmail.com'>
            CONTACT
          </a>
          <Link to='/' onClick={() => setShow(true)}>
            RESUME
        </Link>
        </nav>
      </div>

      <Modal show={show} size={'lg'} onHide={() => hide()}>
        <Modal.Body>
          <img src={resume} alt='resume' style={{ width: '1000px', maxWidth: '100%' }} />
        </Modal.Body>
        <Modal.Footer style={{ height: '175px' }}>
          <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>
            <a href={resumePDF} className='down-resume' download>
              Download PDF
            </a>
            <div className='down-resume' onClick={() => hide()}>Close</div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Navbar;