const images = {}

function importAll(r) {
  let images = [];
  r.keys().forEach((item, index) => { images[index] = r(item).default; });
  return images;
}

images.cheese = importAll(require.context('./cheese', false, /\.(png|jpe?g|svg)$/));
images.AA = importAll(require.context('./AA', false, /\.(png|jpe?g|svg)$/));
images.neddie = importAll(require.context('./neddie', false, /\.(png|jpe?g|svg)$/));
images.wine = importAll(require.context('./wine', false, /\.(png|jpe?g|svg)$/));
images.paulas = importAll(require.context('./paulas', false, /\.(png|jpe?g|svg)$/));
images.kasala = importAll(require.context('./kasala', false, /\.(png|jpe?g|svg)$/));
images.mbContent = importAll(require.context('./mbContent', false, /\.(png|jpe?g|svg)$/));
images.sakuracon = importAll(require.context('./sakuracon', false, /\.(png|jpe?g|svg)$/));
images.mbMarketing = importAll(require.context('./mbMarketing', false, /\.(gif|png|jpe?g|svg)$/));

export default images;